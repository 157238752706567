import {createFeature, createReducer, on} from '@ngrx/store';
import {IEmployeesState} from './types/employees-state.interface';
import {employeeActions} from './actions';

const initialState: IEmployeesState = {
  currentEmployee: null,
  aliasView: false,
  redirectPath: null,
  isLoading: true,
  isLoadingBackdrop: false,
  currentYear: null,
  currentMonth: null,
  currentCalendarMonth: null,
  currentWorkRecord: null,
  data: {
    employees: [],
    absences: [],
    workRecords: [],
    workTimeSchemes: [],
    carryOverRecords: [],
    workingTimeRegulation: null,
    years: [],
  },
};

const employeesFeature = createFeature({
  name: 'employees',
  reducer: createReducer(
    initialState,
    on(employeeActions.fetchEmployees, (state) => ({
      ...state,
      isLoading: true,
    })),
    on(employeeActions.successFetchingEmployees, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
    })),
    on(employeeActions.failedFetchingEmployees, (state) => ({
      ...initialState,
      isLoading: false,
    })),
    on(employeeActions.changeCoreTime, (state) => ({
      ...state,
      isLoadingBackdrop: true,
    })),
    on(employeeActions.changeCoreTimeSuccess, (state) => ({
      ...state,
      isLoadingBackdrop: false,
    })),
    on(employeeActions.changeCoreTimeFailed, (state) => ({
      ...state,
      isLoadingBackdrop: false,
    })),
    on(employeeActions.setCurrentEmployee, (state, action) => ({
      ...state,
      currentEmployee: action.payload,
    })),
    on(employeeActions.setAlias, (state, action) => ({
      ...state,
      aliasView: true,
      currentEmployee: action.payload.userId,
    })),
    on(employeeActions.setRedirectPath, (state, action) => ({
      ...state,
      redirectPath: action.payload,
    })),
    on(employeeActions.removeRedirectPath, (state) => ({
      ...state,
      redirectPath: null,
    })),
    on(employeeActions.removeAlias, (state) => ({
      ...state,
      currentEmployee: null,
      aliasView: false,
    })),
    on(employeeActions.stopLoading, (state) => ({
      ...state,
      isLoading: false,
    })),
    on(employeeActions.setCurrentYear, (state, action) => ({
      ...state,
      currentYear: action.payload,
    })),
    on(employeeActions.setMonthOverview, (state, action) => ({
      ...state,
      currentMonth: action.payload.month,
      currentYear: action.payload.year,
      isLoading: true,
    })),
    on(employeeActions.setEmployeeMonth, (state, action) => ({
      ...state,
      currentMonth: action.payload,
      isLoading: true,
    })),
    on(employeeActions.setCalendarMonth, (state, action) => ({
      ...state,
      currentCalendarMonth: action.payload,
    })),
    on(employeeActions.resetMonthOverview, (state) => ({
      ...state,
      currentMonth: null,
    })),
    on(employeeActions.setWorkRecord, (state, action) => ({
      ...state,
      currentWorkRecord: action.payload,
    })),
    on(employeeActions.logout, (state) => initialState),
  ),
});

export const {
  name: employeesFeatureKey,
  reducer: employeesReducer,
  selectData: selectEmployeesStateData,
  selectEmployeesState,
  selectCurrentEmployee: selectCurrentEmployeeID,
  selectAliasView,
  selectRedirectPath: selectEmployeesStateRedirectPath,
  selectCurrentYear: selectEmployeesStateCurrentYear,
  selectCurrentMonth: selectEmployeesStateSelectedMonth,
  selectCurrentCalendarMonth: selectEmployeesStateCalendarMonth,
  selectIsLoading: selectIsLoadingEmployeeState,
  selectIsLoadingBackdrop: selectIsLoadingBackdropEmployeeState,
} = employeesFeature;
